.dv-main-content-size{
    position: fixed;
    width: 100%;
    height: 100%;
}
.dv-custom-card-body p,.dv-custom-card-body h6{
    margin-bottom: 0;
}
.dv-custom-card-body h6{
    font-weight: bold;
}
.dv-custom-card-body p{
    color: #535252;
}
.dv-main-content{
    /*width: calc( 100% - 230px);*/
    position: fixed;
    width: 83%;
    left: 0;
    height: 85%;
    background: rgba(203,203,210,0.15);
}
.dv-top-menu-in-page{
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    width: 100%;
    background: #e5e5e5;
    box-shadow: 0 5px 5px 2px #e5e5e5;
    color: #000;
}
.dv-top-menu-in-page h4{
    margin-bottom: 0;
}
.example input{
    padding: 0.5rem;
    border-radius:0 0.5rem 0.5rem 0;
    border: none;
}
button:focus{
    outline: none;
    box-shadow: none;
    border: none;
}
.example button{
    padding:0.5rem 1rem;
    border-radius: 0.5rem 0 0 0.5rem;
    border: none;
    background: #7abaff;
    color: #fff;
}
.dv-dropdown-projects{
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #cccccc;
    min-height: 22px;
    position: relative;
}
.dv-dropdown-projects a{
    color: #868686;
    transition: all 0.5s ease;
}
.dv-dropdown-projects .dropdown-menu{
    width: 100%;
}
.dv-dropdown-projects .dropdown-toggle{
    padding-right: 0.7rem;
}
.dv-dropdown-projects a:hover{
    color: var(--main-blue);
}
.dv-dropdown-projects .dropdown-menu{
    text-align: right;
}
.dv-dropdown-projects .dropdown-toggle::after{
    margin-right: 0.3rem;
    vertical-align: middle !important;
}
/*Lazy Load*/
.dv-scroll{
    height: 87vh;
    overflow-y: auto;
    overflow-x: hidden;
}
._3vLmCG3bB3CM2hhAiQX1tN{
    text-align: right !important;
}
._7ahQImyV4dj0EpcNOjnwA {
    background: #4894e5 !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}
.dv-table-project{
    width: 100%;
    text-align: right;
    border-collapse: collapse;
    /*position: absolute;*/
    /*left: 50%;*/
    /*transform: translate(-50% , 0%);*/
}
.dv-table-project td,.dv-table-project th {
    border: 1px solid #dddddd;
    text-align: justify;
    padding: 8px;
}
.dv-table-project tr:nth-child(even) {
    background-color: #dddddd;
}

/************* Responsive *****************/
@media screen and (max-width: 992px){
    .dv-main-content{
        width: 89%;
    }
    .dv-main-content-size{
        min-height: 48rem;
        /*height: 550px;*/
    }
}
@media screen and (max-width: 768px){
    .example{
        width: 100%;
        position: relative;
        right: -2.5rem !important;
    }
    .dv-top-menu-in-page h4{
        margin-right: -2rem;
    }
    .example input{
        width: 80%;
    }
    .dv-custom-table tr, .dv-custom-table td{
        width: 200px;
    }
    .dv-custom-table{
        width: 675px !important;
    }

}
@media screen and (max-width: 600px) {
    .example {
        right: -1rem !important;
    }
}
@media screen and (max-width: 576px) {
    .example {
        right: -0.5rem !important;
    }
    .dv-main-content {
        width: 87%;
    }
}
@media screen and (max-width: 390px) {
    .example input{
        width: 72%;
    }
    .dv-main-content {
        width: 83%;
    }
}
@media screen and (max-width: 350px) {
    .example {
        right: -0.5rem !important;
    }
    .dv-main-content {
        width: 80%;
    }
}