.rtl{
    direction: rtl;
}
.dv-dropdown a{
    color: #fff !important;
}
.dv-dropdown .dropdown-menu{
    text-align: right;
    background: var(--main-blue);
    background: linear-gradient(
            156deg
            , rgba(3,22,42,1) 0%, rgba(3,124,157,1) 100%);
    color: #000 !important;
    padding:0 !important;
}
.dv-dropdown .dropdown-menu .dropdown-item{
    height: 40px !important;
    display: flex;
    align-items: center;
    color: #000;
    transition: all 0.3s linear;
}
.dv-dropdown .dropdown-menu .dropdown-item:hover{
    color:#000 !important;
}