.dv-footer {
    position: fixed;
    bottom: 0;
    background: var(--main-blue);
    background: linear-gradient(156deg, rgba(3,22,42,1) 0%, rgba(3,124,157,1) 100%);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin: auto;
}
.dv-rahkar-color{
    color:#fff;
}
.dv-footer-p{
    color: #fff;
    margin-bottom: 0;
}