.bg-login-form{
    background: var(--main-blue);
    background: linear-gradient(156deg, rgba(3,22,42,1) 0%, rgba(3,124,157,1) 100%);
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: fixed;
    height: 100%;
    width: 100%;
}
.dv-form{
    background: #fff;
    border: 1px solid #fff;
    border-radius: 0.7rem;
    box-shadow: 0 0 5px 2px #e7e7e7;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.dv-logo-image{
    width: 10rem;
}
.dv-login-form input{
    border: 1px solid #d6d6d6;
    background: #d6d6d6;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
input:focus{
    outline: none;
}
.dv-login-btn{
    width: 50%;
    background: rgba(3,22,42 , 0.9);
    color: #f8f9fa;
    height: 3rem;
    border: 1px solid rgba(3,22,42,0.7);
    border-radius: 0.5rem;
    transition: all 0.4s linear;
}
.Toastify__toast-body{
    font-family: 'iranyekan';
    text-align: right !important;
    direction: rtl !important;
}
.cursor-pointer{
    cursor: pointer;
}
.text-m-light-gray {
    color: #919191;
}
.simple-input {
    border-radius: 10px;
    border: 1px solid #707070;
    padding: .5rem 1rem;
}
.btn-main-success {
    background-color: #00c45a !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding-bottom: .7rem !important;
}
.dv-login-btn:hover{
    background: var(--main-blue);
    border: 1px solid var(--main-blue);
    transform: translateY(-0.5rem);
}
@media screen and (max-width: 768px){
    .bg-login-form{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .dv-login-btn{
        width: 100%;
    }
    .dv-form{
        margin-top: 4rem;
    }
    .dv-logo-image{
        width: 8rem;
    }
    .dv-h4{
        font-size: 18px;
    }
}