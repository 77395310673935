* {
    box-sizing: border-box;
}
a,a:hover{
    text-decoration: none !important;
}
.bg-dark{
    background: var(--main-blue);
    background: linear-gradient(156deg, rgba(3,22,42,1) 0%, rgba(3,124,157,1) 100%);
}
.nav-container {
    position: fixed;
    height: 100%;
    width: 17% !important;
    /*width: 230px;*/
    /*box-shadow: 1px 3px 3px 3px black;*/
    border-left: 1px solid #9e9e9e;
    transition: all 0.5s linear;
    z-index: 9;
    background: #e5e5e5;
}
.dv-nav {
    list-style-type: none;
    margin:0;
    padding:0;
    width: 100%;
}

.nav-container .dv-nav li{
    height: 60px;
    position:relative;
    background: #e5e5e5;

}
.nav-container a {
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 0;
    display:flex;
    align-items: center;
    height:100%;
    width:100%;
    line-height:50px;
    color:#121212;
    text-transform: uppercase;
    font-weight: bold;
    padding-right:25%;
    border-right: 5px solid transparent;
    letter-spacing: 1px;
    transition:all 0.3s linear;
}
.nav-container .text {
    transition: all .4s linear;
}
.nav-container li:hover .text{
    margin-right:0.5rem;
}
.nav-container .active a {
    color: #fff;
    border-right:5px solid rgba(3,124,157,1);
    background-color: rgba(3,124,157,1);
    outline:0;
}
.nav-container li:not(.active):hover a{
    color: #eee;
    border-right:5px solid rgba(3,124,157,1);
    background-color: rgba(3,124,157,1);
}

.nav-container span[class ^= "fa"]{
    position:absolute;
    right:20px;
    font-size:1.5em;
    transition: all 0.3s linear;
}

@media only screen and (max-width : 992px){
    .nav-container .text{
        display:none;
    }
    .nav-container, a {
        width: 70px !important;
    }
    .nav-container a:hover{
        width: 200px !important;
        z-index:1;
        border-top: 1px solid rgba(255,255,255,0.1);
        border-bottom: 1px solid black;
        box-shadow: 0 0 1px 1px black;
    }
    .nav-container a:hover .text {
        display:block;
        padding-right: 30%;
    }

}