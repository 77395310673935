.dv-main-content-size{
    position: fixed;
    width: 100%;
    height: 100%;
}
.dv-main-content{
    /*width: calc( 100% - 230px);*/
    position: fixed;
    width: 83%;
    left: 0;
    height: 85%;
    background: rgba(203,203,210,0.15);
}
.dv-top-menu-in-page{
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    width: 100%;
    background: #e5e5e5;
    box-shadow: 0 5px 5px 2px #e5e5e5;
    color: #000;
}
.dv-top-menu-in-page h4{
    margin-bottom: 0;
}
.example input{
    padding: 0.5rem;
    border-radius:0 0.5rem 0.5rem 0;
    border: none;
}
button:focus{
    outline: none;
    box-shadow: none;
    border: none;
}
.example button{
    padding:0.5rem 1rem;
    border-radius: 0.5rem 0 0 0.5rem;
    border: none;
    background: #7abaff;
    color: #fff;
}
.dv-custom-table thead tr{
    background: var(--main-blue);
    background: linear-gradient(
            156deg
            , rgba(3,22,42,1) 0%, rgba(3,124,157,1) 100%);
    color: #fff;
}
.dv-custom-table tbody tr{
    background-color: rgba(0,0,0,.05);
}
.dv-custom-table th:last-child{
    width: 250px !important;
}
.dv-custom-table th , .dv-custom-table td{
    text-align: center;
    vertical-align: middle !important;
}
.dv-change-status{
    width: 120px;
}
.dv-dropdown .dropdown-toggle{
    padding-right: 0.7rem;
}

/*Lazy Load*/
.dv-scroll{
    height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
}

/************* Responsive *****************/
@media screen and (max-width: 992px){
    .dv-main-content{
        width: 89%;
    }
    .dv-main-content-size{
        min-height: 48rem;
        /*height: 550px;*/
    }
}
@media screen and (max-width: 768px){
    .example{
        width: 100%;
        position: relative;
        right: -2.5rem !important;
    }
    .dv-top-menu-in-page h4{
        margin-right: -2rem;
    }
    .example input{
        width: 80%;
    }
    .dv-custom-table tr, .dv-custom-table td{
        width: 200px;
    }
    .dv-custom-table{
        width: 675px !important;
    }

}
@media screen and (max-width: 600px) {
    .example {
        right: -1rem !important;
    }
}
@media screen and (max-width: 576px) {
    .example {
        right: -0.5rem !important;
    }
    .dv-main-content {
        width: 87%;
    }
}
@media screen and (max-width: 390px) {
    .example input{
        width: 72%;
    }
    .dv-main-content {
        width: 83%;
    }
}
@media screen and (max-width: 350px) {
    .example {
        right: -0.5rem !important;
    }
    .dv-main-content {
        width: 80%;
    }
}