/*@keyframes ldio-helqft1s9s {*/
/*    0% { transform: rotate(0deg) }*/
/*    50% { transform: rotate(180deg) }*/
/*    100% { transform: rotate(360deg) }*/
/*}*/
/*.ldio-helqft1s9s div {*/
/*    position: absolute;*/
/*    animation: ldio-helqft1s9s 1s linear infinite;*/
/*    width: 160px;*/
/*    height: 160px;*/
/*    top: 0;*/
/*    left: 20px;*/
/*    border-radius: 50%;*/
/*    box-shadow: 0 4px 0 0 #6D76C9;*/
/*    transform-origin: 80px 82px;*/
/*}*/
/*.ldio-helqft1s9s2 div {*/
/*    position: absolute;*/
/*    animation: ldio-helqft1s9s 1s linear infinite;*/
/*    width: 160px;*/
/*    height: 160px;*/
/*    top: 0;*/
/*    left: 20px;*/
/*    border-radius: 50%;*/
/*    box-shadow: 0 4px 0 0 #6D76C9;*/
/*    transform-origin: 80px 82px;*/
/*}*/
/*.loadingio-spinner-eclipse-nwfu0za0w8f {*/
/*    width: 250px;*/
/*    height: 250px;*/
/*    display: inline-block;*/
/*    overflow: hidden;*/
/*}*/
/*.ldio-helqft1s9s {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    position: relative;*/
/*    transform: translateZ(0) scale(1);*/
/*    backface-visibility: hidden;*/
/*    transform-origin: 0 0; !* see note above *!*/
/*}*/
/*.ldio-helqft1s9s div { box-sizing: content-box; }*/
/*!* generated by https://loading.io/ *!*/

#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0,0, 0.2);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader.hidden {
    opacity: 0;
    pointer-events: none;
}
.dark-blue{
    color: #6D76C9;
    padding-top: 12rem;
    padding-left: 1rem;
}
/*@media screen and (max-width: 568px){*/
/*    .ldio-helqft1s9s {*/
/*        margin: auto;*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        align-self: center;*/
/*        align-items: center;*/
/*    }*/
/*    .ldio-helqft1s9s div{*/
/*        left: 40px;*/
/*    }*/
/*}*/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #6D76C9;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}