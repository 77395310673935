@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/eot/iranyekanwebregularfanum.eot');
  src: url('assets/fonts/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('assets/fonts/woff/iranyekanwebregularfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('assets/fonts/ttf/iranyekanwebregularfanum.ttf') format('truetype');
}
:root{
  --main-blue : rgb(3, 22, 42);
}
body {
  margin: 0;
  font-family: 'iranyekan' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
